import React, { useContext, useEffect } from "react"

import { useRecoilValue, useSetRecoilState } from "recoil"
import {
  hasOrgAtom,
  is3PLsEnabledSelector,
  isAuthAtom,
  isPayingUserSelector,
  isSupportRoleSelector,
  userAtom,
} from "@/atoms/userAtom"
import { Loader } from "@/UI"

import { useFlagsmith } from "flagsmith/react"
import { SandboxContext } from "@/layout/Nav/SandboxSwitcher"
import axios from "@/modules/shared/AxiosInterceptor"
import { useUser } from "@/hooks/useUser"
import { LOGIN_PAGE_PATH } from "@/constants/routes"
import { routes } from "./routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import { isEmpty } from "lodash"
import { getActiveOrg, getActiveTokens } from "@/utils/localStorage"
import useMediaQuery from "@/utils/useMediaQuery"
import { DateTime } from "luxon"
import { Spin } from "antd"
import { useTranslation } from "react-i18next"
import InitUserLoader from "./InitUserLoader"
import { IState } from "flagsmith/types"

interface AuthProps {}

const { initIntercomWindow } = require("next-intercom")

// TODO: Correctly type Component
const withAuth = (Component: any) => {
  const Auth = (props: AuthProps) => {
    const { ready } = useTranslation()
    const flagsmith = useFlagsmith()
    const { sandboxRouter } = useSandboxRouter()
    const setUser = useSetRecoilState(userAtom)
    const { user, fetchUserProfile } = useUser()
    const isMobile = useMediaQuery("(max-width: 855px)")
    const sandbox = useContext(SandboxContext)

    const hasOrg = useRecoilValue(hasOrgAtom)
    const isAuth = useRecoilValue(isAuthAtom)
    const is3PLsEnabled = useRecoilValue(is3PLsEnabledSelector)
    const isSupportRole = useRecoilValue(isSupportRoleSelector)
    const isPayingOrg = useRecoilValue(isPayingUserSelector)

    const route = routes[sandboxRouter.pathname]

    useEffect(() => {
      const hasTestUrl = sandboxRouter.asPath.includes("/test/") ? true : false
      sandbox.enabled = !sandbox.enabled && hasTestUrl ? true : sandbox.enabled

      // PR Question: This is needed to avoid a flash as initial redirect happens before interceptors are set
      // Should AxiosInterceptors be a HOC ?
      const { accessToken } = getActiveTokens()
      if (accessToken) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
      }

      const activeOrg = getActiveOrg()
      if (activeOrg) {
        axios.defaults.headers.common["x-org-id"] = activeOrg
      } else {
        delete axios.defaults.headers.common["x-org-id"]
      }

      if (sandbox.enabled) {
        axios.defaults.headers.common["x-sandbox"] = sandbox.enabled
      } else {
        delete axios.defaults.headers.common["x-sandbox"]
      }
    }, [])

    useEffect(() => {
      const { accessToken } = getActiveTokens()
      if (route?.noAuthPage || user.userFetched || !accessToken) {
        setUser((user) => ({ ...user, userFetched: true }))
        return
      }
      fetchUserProfile()
    }, [sandbox.enabled])

    const initializeHotjar = async () => {
      const { hotjar } = await import("react-hotjar")

      const isNewUser = !!user.createdAt
        ? DateTime.fromMillis(user.createdAt) > DateTime.now().minus({ month: 1 })
        : true

      if (
        process.env.NEXT_PUBLIC_REACT_APP_ENVIRONMENT === "production" &&
        !hotjar.initialized() &&
        !user.email.includes("kosmo.delivery") &&
        isNewUser
      ) {
        hotjar.initialize({ id: 3532459, sv: 6 })
      } else {
        return
      }
      if (hotjar.initialized()) {
        hotjar.identify(user.email, { orgId: user.org?.id, id: user.id })
      }
    }

    const initializeFlagsmith = async () => {
      try {
        await flagsmith.identify(user.id, { org_id: user.org.id })
      } catch {
        const flagsmithState = JSON.parse(localStorage?.getItem("BULLET_TRAIN_DB") || "{}")
        if (!!flagsmithState && flagsmithState?.api && !isEmpty(flagsmithState?.flags)) {
          flagsmith.setState(flagsmithState as IState)
        }
      }
    }

    useEffect(() => {
      if (user?.userFetched && isAuth && !flagsmith.identity) {
        initializeFlagsmith()
      }
      initializeHotjar()
    }, [user])

    useEffect(() => {
      if (isAuth) {
        initIntercomWindow({
          appId: process.env.NEXT_PUBLIC_INTERCOM_APPID,
          email: user?.email,
          user_id: user?.id,
          company: {
            company_id: user?.org?.id,
            name: user?.org?.name,
            industry: user?.org?.country,
            website: user?.org?.email,
          },

          companies: user?.orgs?.map((org) => ({
            company_id: org?.id,
            name: org?.name,
            industry: org?.country,
          })),
        })
      }
    }, [user?.org?.id])

    if (!user?.userFetched || !ready) {
      return <InitUserLoader translationReady={ready} />
    }

    // Renders public and private components if allowed
    const shouldRedirectUser = route?.shouldRedirect
      ? route?.shouldRedirect({
          isAuth,
          hasOrg,
          sandboxEnabled: sandbox.enabled,
          isMobile,
          is3PLsEnabled,
          isSupportRole,
          isPayingOrg,
        })
      : true

    // Renders requested page
    if (!shouldRedirectUser) {
      return <Component {...props} />
      // Shopify redirection case
    } else if (sandboxRouter?.query?.redirectPath && isAuth) {
      const [targetPath, queryParams] = (sandboxRouter?.query?.redirectPath as string)?.split("?")
      sandboxRouter?.push({ pathname: targetPath, query: queryParams })
    } else if (route?.redirectTo) {
      // Todo: return value from shouldRedirect
      const { newPath, queryParams } = route?.redirectTo({
        hasOrg,
        isAuth,
        sandboxEnabled: sandbox.enabled,
        query: sandboxRouter.query,
        asPath: sandboxRouter.asPath,
        isMobile,
        is3PLsEnabled,
        isSupportRole,
        isPayingOrg,
      })
      sandboxRouter.pushWithContext({ targetHref: newPath, query: queryParams })
      return (
        <>
          {user?.userFetched ? (
            <InitUserLoader translationReady={ready} />
          ) : (
            <div className="absolute left-2/4 top-2/4">
              <Spin indicator={<Loader fontSize={40} color="black" size="small" />} />
            </div>
          )}
        </>
      )
    } else {
      // Default redirect to login
      sandboxRouter.push({ pathname: LOGIN_PAGE_PATH, query: sandboxRouter.query })
      return (
        <div className="absolute left-2/4 top-2/4">
          <Spin indicator={<Loader fontSize={40} color="black" size="small" />} />
        </div>
      )
    }
    // Default fallthrough case
    return (
      <div className="absolute left-2/4 top-2/4">
        <Spin indicator={<Loader fontSize={40} color="black" size="small" />} />
      </div>
    )
  }
  // Copy getInitial props so it will run as well
  if (Component.getInitialProps) {
    Auth.getInitialProps = Component.getInitialProps
  }

  return Auth
}

export default withAuth
