import React from "react"
import { useTranslation } from "react-i18next"

const LoginImagePlaceholder = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full h-full bg-[#F4F7FA]">
      <div className="flex flex-col text-center px-20 mt-10 gap-y-10">
        <div className="flex flex-row">
          <div className="flex flex-col text-left">
            <p className="font-semibold text-black text-2xl mt-10 pb-0 mb-2">
              {t("login.RiderCarousel.mainTitle")}
            </p>
            <p className="text-lg text-gray-400">{t("login.RiderCarousel.subTitle")}</p>
          </div>
        </div>
        <div className="flex rounded-lg">
          <img
            src="/assets/images/carouselImages/login_carousel_optimize.png"
            alt="carousel-image"
            className="mx-auto select-none"
          />
        </div>
      </div>
    </div>
  )
}

export default LoginImagePlaceholder
