import React, { createRef, useEffect } from "react"
import Main from "@/templates/Main"
import Meta from "@/layout/Meta"

import useMediaQuery from "@/utils/useMediaQuery"

import LoginImagePlaceholder from "@/modules/login/LoginImagePlaceholder"
import withAuth from "@/modules/HOC/WithAuth/withAuth"
import { NextPage, NextPageContext } from "next"

import { useTranslation } from "react-i18next"

import { getLock, LockParams } from "@/modules/shared/AuthLock"
import { Card } from "antd"
import { isEmpty } from "lodash"
import { useSetRecoilState } from "recoil"
import { userAtom } from "@/atoms/userAtom"
import { BASKIT_BRANDING } from "@/modules/shared/ThemeProvider/InjectBranding"

interface ILoginProps {
  hostname?: string
}

export const enhanceAuth0Lock = () => {
  // add id to data-provider="google-oauth2"
  const googleButton = document.querySelector('[data-provider="google-oauth2"]')
  if (googleButton) {
    const id = window.location.pathname.includes("signup")
      ? "google-oauth2-signup"
      : "google-oauth2-login"
    googleButton.setAttribute("id", id)
  }

  const tabs = document.querySelectorAll(".auth0-lock-tabs li")
  // keep trying to get tabs until it's available
  if (tabs.length === 0) {
    setTimeout(() => {
      enhanceAuth0Lock()
    }, 100)
    return
  }
  tabs.forEach((t) => {
    // The idea of the event is that we check the text of the tab
    // and change the URL accordingly.
    // Ff we track a tab switch, we need to delete tabs html elements
    // and add event listener again.
    // If we don't delete tabs html elements, this funtion will add event listeners to old elements
    // It's important because auth0 lock re-render tabs html elements when we switch tabs
    t.addEventListener("click", function () {
      const text = t.innerHTML
      if (text.includes("Sign Up")) {
        if (window.location.pathname.includes("signup")) {
          return
        }
        window.history.pushState({}, "", "/signup")
      } else {
        if (window.location.pathname.includes("login")) {
          return
        }
        window.history.pushState({}, "", "/login")
      }
      // delete tabs html elements so that we can add event listener again
      tabs.forEach((t) => {
        t.remove()
      })
      enhanceAuth0Lock()
    })
  })
}

const Login: NextPage<ILoginProps> = ({ hostname }) => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery("(min-width: 1024px)")
  const authRef = createRef<HTMLDivElement>()
  const setUser = useSetRecoilState(userAtom)
  const isCustomSubdomain =
    hostname?.includes("baskit") || (window && window?.location?.origin?.includes("baskit"))

  useEffect(() => {
    setUser((user) => ({
      ...user,
      preference: {
        ...user?.preference,
        branding: isCustomSubdomain ? BASKIT_BRANDING : { ...user?.preference?.branding },
      },
      customSubdomain: isCustomSubdomain ? "baskit" : undefined,
    }))
  }, [isCustomSubdomain])

  const initLock = () => {
    try {
      let query = {}
      let urlParams = new URLSearchParams(window.location.search)
      const redirectPath = urlParams.get("redirectPath")
      if (redirectPath) {
        query = { redirectPath }
      }

      const lockParams: LockParams = {
        ...(isCustomSubdomain ? { customSubdomain: "baskit" } : {}),
        ...(!isEmpty(query) ? { state: { query } } : {}),
      }
      const lock = getLock(lockParams)

      lock.show()

      lock.on("show", () => {
        enhanceAuth0Lock()
      })
    } catch (error) {
      console.log(error, "init lock")
    }
  }

  useEffect(() => {
    if (authRef?.current) {
      setTimeout(() => {
        initLock()
      }, 500)
    }
  }, [authRef.current])

  return (
    <Main meta={<Meta title={t("login.common.login")} description={t("login.Meta.description")} />}>
      <div id="login" className="flex flex-row h-full">
        <div
          className={`${
            isCustomSubdomain ? "" : "lg:w-5/12"
          } w-full flex flex-col items-center justify-between overflow-y-scroll`}
        >
          <Card className="h-fit w-fit mt-10 shadow-lg" id="auth-container" ref={authRef} />
        </div>
        {isDesktop && !isCustomSubdomain && (
          <div className="flex w-7/12">
            <LoginImagePlaceholder />
          </div>
        )}
      </div>
    </Main>
  )
}

Login.displayName = "login"

Login.getInitialProps = (ctx: NextPageContext) => {
  const hostname = ctx?.req?.headers.host
  return { hostname }
}

export default withAuth(Login) as any
