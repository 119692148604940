// import { userAtom } from "@/atoms/userAtom"
import { Loader } from "@/UI"
import { Card } from "antd"
// import Image from "next/image"
import React from "react"
import { useTranslation } from "react-i18next"
// import { useRecoilValue } from "recoil"

// const { Title } = Typography
const InitUserLoader: React.FC<{ translationReady?: boolean }> = ({ translationReady }) => {
  const { t } = useTranslation()
  // const { preference } = useRecoilValue(userAtom)
  // const { branding } = preference

  return (
    <div className="flex w-full h-[100vh] items-center justify-center">
      <Card className="shadow-md min-w-[250px] h-fit">
        <div className="flex flex-col items-center gap-y-2">
          <div className="flex items-center">
            <Loader fontSize={50} color="black" />
            {/* <Image
              className="cursor-pointer"
              src={branding?.companyLogoUrl || "/assets/images/logo.svg"}
              alt="logo"
              width={branding?.width || 90}
              height={branding?.height || 90}
              priority={true}
            />
            <Title level={3} className="text-black font-semibold w-fit !mb-0 shine">
              Kosmo
            </Title> */}
          </div>
          {translationReady && <p className="italic mt-2">{t("common.loadingProfile")}</p>}
        </div>
      </Card>
    </div>
  )
}

export default InitUserLoader
